import React from 'react'
import { AdminTabs } from './Tabs/AdminTabs'
import { EUserRoles } from '../../enums/roles'
import { OperatorTabs } from './Tabs/OperatorTabs'
import { useUserSelector } from '../../store/selectors/auth'
import Sidebar from './Sidebar'

const useSidebar = () => {
  const user = useUserSelector()
  const render = (): React.ReactNode => {
    switch (user?.role) {
      case EUserRoles.ADMIN: {
        return <Sidebar tabs={AdminTabs().filter((el) => el.show)} />
      }
      case EUserRoles.OPERATOR: {
        return <Sidebar tabs={OperatorTabs().filter((el) => el.show)} />
      }
      case EUserRoles.MANAGER: {
        return <></>
      }
      default:
        return <></>
    }
  }

  return <>{render()}</>
}

export default useSidebar
