import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
  onClick?: () => void
}

const IconEdit = ({ width = '24px', height = '24px', fill = '#000', onClick }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      onClick={onClick}
    >
      <g>
        <path
          fill={fill}
          d='M16,21.75H6A3.383,3.383,0,0,1,2.25,18V8A3.383,3.383,0,0,1,6,4.25H9a.75.75,0,0,1,0,1.5H6c-1.577,0-2.25.673-2.25,2.25V18c0,1.577.673,2.25,2.25,2.25H16c1.577,0,2.25-.673,2.25-2.25V15a.75.75,0,0,1,1.5,0v3A3.383,3.383,0,0,1,16,21.75Z'
        />
        <path
          fill={fill}
          d='M20.57,7.08,18.68,8.96,15.04,5.32l1.88-1.89a1.452,1.452,0,0,1,2.05-.01l1.61,1.61A1.452,1.452,0,0,1,20.57,7.08Z'
        />
        <polygon
          fill={fill}
          opacity={0.3}
          points='18.68 8.96 11.61 16 8 16 8 12.39 15.04 5.32 18.68 8.96'
        />
      </g>
    </svg>
  )
}

export default IconEdit
