import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconUser = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} cx='12.009' cy='6.5' r='4' />
        <path
          fill={fill}
          opacity={0.3}
          d='M14,12.5H10c-4.06,0-5.5,2.973-5.5,5.519C4.5,20.3,5.711,21.5,8,21.5H16c2.292,0,3.5-1.2,3.5-3.481C19.5,15.473,18.06,12.5,14,12.5Z'
        />
      </g>
    </svg>
  )
}

export default IconUser
