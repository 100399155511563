import React, { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorAppPage } from './ErrorAppPage/ErrorAppPage'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true, error: _ }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorAppPage error={this.state.error} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
