import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconPresentationPoll = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M20,3.75V13c0,2.29-1.21,3.5-3.5,3.5h-9C5.21,16.5,4,15.29,4,13V3.75Z'
        />
        <path fill={fill} d='M21,3.75H3a.75.75,0,0,1,0-1.5H21a.75.75,0,0,1,0,1.5Z' />
        <path
          fill={fill}
          d='M15.62,21.42a.751.751,0,0,1-.62.33.741.741,0,0,1-.42-.13L12,19.9,9.42,21.62a.749.749,0,0,1-.84-1.24l2.67-1.78V16.5h1.5v2.1l2.67,1.78A.751.751,0,0,1,15.62,21.42Z'
        />
        <path
          fill={fill}
          d='M8.5,13.25a.75.75,0,0,1-.75-.75v-4a.75.75,0,0,1,1.5,0v4A.75.75,0,0,1,8.5,13.25Zm4.25-.75v-6a.75.75,0,0,0-1.5,0v6a.75.75,0,0,0,1.5,0Zm3.5,0v-2a.75.75,0,0,0-1.5,0v2a.75.75,0,0,0,1.5,0Z'
        />
      </g>
    </svg>
  )
}

export default IconPresentationPoll
