import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconUserEye = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} cx='10.009' cy='6.5' r='4' />
        <path
          fill={fill}
          opacity={0.3}
          d='M15.305,13.422A5.69,5.69,0,0,0,12,12.5H8c-4.06,0-5.5,2.97-5.5,5.52C2.5,20.3,3.71,21.5,6,21.5h6.39a.3.3,0,0,0,.3-.3.374.374,0,0,0-.1-.22,8.412,8.412,0,0,1-.68-.98,2.952,2.952,0,0,1,0-3.01,7.37,7.37,0,0,1,3.329-3.035A.3.3,0,0,0,15.305,13.422Z'
        />
        <path
          fill={fill}
          d='M21.8,17.76A5.17,5.17,0,0,0,17.5,15a5.17,5.17,0,0,0-4.3,2.76,1.453,1.453,0,0,0,0,1.48A5.17,5.17,0,0,0,17.5,22a5.17,5.17,0,0,0,4.3-2.76A1.453,1.453,0,0,0,21.8,17.76Zm-4.3,1.99a1.25,1.25,0,0,1-.01-2.5h.01a1.25,1.25,0,0,1,0,2.5Z'
        />
      </g>
    </svg>
  )
}

export default IconUserEye
