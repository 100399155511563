import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconBitcoin = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path fill={fill} d='M14,3V4.03A4.07,4.07,0,0,0,13.5,4H12V3a1,1,0,0,1,2,0Z' />
        <path fill={fill} d='M10,3V4H8V3a1,1,0,0,1,2,0Z' />
        <path fill={fill} d='M14,20v1a1,1,0,0,1-2,0V20Z' />
        <path fill={fill} d='M10,20v1a1,1,0,0,1-2,0V20Z' />
        <path
          fill={fill}
          opacity={0.3}
          d='M16.733,11.617A4.491,4.491,0,0,0,13.5,4H6A1,1,0,0,0,6,6H7V18H6a1,1,0,0,0,0,2h8.5a4.485,4.485,0,0,0,2.233-8.383ZM13.5,6a2.5,2.5,0,0,1,0,5H9V6Zm1,12H9V13h5.5a2.5,2.5,0,0,1,0,5Z'
        />
      </g>
    </svg>
  )
}

export default IconBitcoin
