import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconPhoneTimes = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M14.869,14.794l-1.115,1.665a11.6,11.6,0,0,1-6.211-6.226L9.2,9.128a2.051,2.051,0,0,0,.629-2.752L8.269,3.737a1.5,1.5,0,0,0-1.587-.708l-.016,0a4.526,4.526,0,0,0-3.507,5.6A17.441,17.441,0,0,0,15.373,20.842a4.527,4.527,0,0,0,5.6-3.509h0a1.5,1.5,0,0,0-.7-1.583l-2.642-1.576A2.052,2.052,0,0,0,14.869,14.794Z'
        />
        <path
          fill={fill}
          d='M19.061,6,20.53,4.53a.75.75,0,1,0-1.06-1.06L18,4.939,16.53,3.47a.75.75,0,1,0-1.06,1.06L16.939,6,15.47,7.47a.75.75,0,1,0,1.06,1.06L18,7.061,19.47,8.53a.75.75,0,1,0,1.06-1.06Z'
        />
      </g>
    </svg>
  )
}

export default IconPhoneTimes
