import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFolderShield = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,10v7a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V7A2.652,2.652,0,0,1,6,4h4l3,3h5A2.652,2.652,0,0,1,21,10Z'
        />
        <path
          fill={fill}
          d='M15,11.116A12.584,12.584,0,0,1,12.006,10,15.248,15.248,0,0,1,9,11.167v1.944A4.206,4.206,0,0,0,12,17a4.206,4.206,0,0,0,3-3.889Z'
        />
      </g>
    </svg>
  )
}

export default IconFolderShield
