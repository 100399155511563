import React from 'react'
import { DatePicker, DatePickerProps } from 'antd'
import {
  IconAngleLeft,
  IconAngleRight,
  IconAnglesLeft,
  IconAnglesRight,
  IconCalendar,
} from '../../../utils/icons'

const DatePickerUi: React.FC<DatePickerProps> = (props) => {
  return (
    <DatePicker
      getPopupContainer={() => document.getElementById('App') || document.body}
      suffixIcon={<IconCalendar width='1rem' height='1rem' fill='var(--color-arrows-date)' />}
      nextIcon={<IconAngleRight width='18' height='18' fill='var(--color-arrows-date)' />}
      prevIcon={<IconAngleLeft width='18' height='18' fill='var(--color-arrows-date)' />}
      superNextIcon={<IconAnglesRight width='18' height='18' fill='var(--color-arrows-date)' />}
      superPrevIcon={<IconAnglesLeft width='18' height='18' fill='var(--color-arrows-date)' />}
      {...props}
    />
  )
}

export default DatePickerUi
