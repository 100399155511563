import React, { useMemo } from 'react'
import DropdownUI from '../../../components/ui/DropDownUI/DropdownUi'
import styles from '../../../components/Header/AdminHeader/AdminHeader.module.scss'
import { useUserSelector } from '../../../store/selectors/auth'
import { useNavigate } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { useAppDispatch } from '../../../store/hooks'
import { authLogout } from '../../../store/auth/thunk'
import { useModalContext } from '../../../context/modalContext'
import ManagerUpdateModal from '../../../components/Modals/ManagerUpdate/ManagerUpdate.modal'
import { EUserRoles } from '../../../enums/roles'
import { AdminProfile } from './dropMenu/adminDropMenu'
import { OperatorProfile } from './dropMenu/operatorDropMenu'
import { IconUser } from '../../../utils/icons'
import PayModal from '../../../components/Modals/PayModal/PayModal'
import axiosInstance from '../../../config/axios'
import { serverPath } from '../../../constants/api-endpoints'
import { useMessage } from '../../../hooks/useMessage'
import { errorMapper } from '../../../utils/errorMapper'
import { ErrorMessages } from '../../../constants/errors'
import { appHistory } from '../../../router/history'

interface MenuClickEventHandler {
  key: string
}
export const AdminProfileDrop = () => {
  const user = useUserSelector()
  const dispatch = useAppDispatch()
  const { setModalComponent } = useModalContext()
  const navigator = useNavigate()
  const { error } = useMessage()

  const handler = async (e: MenuClickEventHandler | undefined) => {
    switch (e?.key) {
      case 'profile': {
        user &&
          setModalComponent({
            component: (
              <ManagerUpdateModal
                manager={user}
                update={() => {
                  navigator(routersPath.LOGIN_PAGE)
                }}
              />
            ),
            maskClosable: true,
          })
        break
      }
      case 'exit': {
        dispatch(authLogout())
        break
      }
      case 'powerOff': {
        try {
          await axiosInstance.put(serverPath.CRM_OFF)
          error('CRM выключена')
          dispatch(authLogout)
          appHistory.push(routersPath.LOGIN_PAGE)
        } catch (e) {
          error(errorMapper(ErrorMessages.UPDATE_DATA, e), ErrorMessages.SERVER_ERROR)
        }
        break
      }
      case 'pay': {
        setModalComponent({
          component: <PayModal />,
          maskClosable: true,
        })
        break
      }
      default:
        return
    }
  }

  const userName = useMemo(() => {
    switch (user?.role) {
      case EUserRoles.ADMIN:
        return 'Клоузер'
      case EUserRoles.OPERATOR:
        return 'Оператор'
    }
    return 'Пользователь'
  }, [user])

  const dropMenu = () => {
    switch (user?.role) {
      case EUserRoles.ADMIN:
        return AdminProfile(handler)
      case EUserRoles.OPERATOR:
        return OperatorProfile(handler)
    }
    return AdminProfile(handler)
  }

  return (
    <DropdownUI menu={dropMenu()}>
      <span className={styles.backdrop}>
        <span className={styles.icon}>
          <IconUser width='22' height='22' fill='var(--color-main)' />
        </span>
        <b>{user?.nickName ?? userName}</b>
      </span>
    </DropdownUI>
  )
}
