import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconHomeRoofDollar = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21.477,9.085l-7.413-6.1a3.258,3.258,0,0,0-4.128,0l-7.413,6.1a.75.75,0,1,0,.954,1.158L4.5,9.4V18c0,2.29,1.21,3.5,3.5,3.5h8c2.29,0,3.5-1.21,3.5-3.5V9.4l1.023.841a.75.75,0,1,0,.954-1.158Z'
        />
        <path
          fill={fill}
          d='M14.5,15.113a2.244,2.244,0,0,0-1.682-2.18l-1.266-.319a.733.733,0,0,1-.4-.268A.749.749,0,0,1,11,11.887a.737.737,0,0,1,.725-.748h.55a.734.734,0,0,1,.721.666.758.758,0,0,0,.827.663.75.75,0,0,0,.663-.828A2.235,2.235,0,0,0,12.75,9.7V9.5a.75.75,0,0,0-1.5,0v.193a2.251,2.251,0,0,0-.068,4.374l1.266.319a.756.756,0,0,1,.333,1.26.7.7,0,0,1-.506.215h-.55A.734.734,0,0,1,11,15.2a.758.758,0,0,0-.827-.663.75.75,0,0,0-.663.828,2.236,2.236,0,0,0,1.736,1.945v.2a.75.75,0,0,0,1.5,0v-.193a2.2,2.2,0,0,0,1.1-.609A2.241,2.241,0,0,0,14.5,15.113Z'
        />
      </g>
    </svg>
  )
}

export default IconHomeRoofDollar
