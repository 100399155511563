import React from 'react'
import cls from './BalanceBtn.module.scss'
import { useBalance } from '../../../store/selectors/crmInfo'
import BalanceModal from '../../Modals/PayModal/BalanceModal'
import { useModalContext } from '../../../context/modalContext'
import IconDollar from '../../../utils/icons/elements/IconDollar'

export const BalanceBtn: React.FC = () => {
  const balance = useBalance()

  const { setModalComponent } = useModalContext()
  const handler = () => {
    setModalComponent({
      component: <BalanceModal />,
      maskClosable: true,
    })
  }
  return (
    <div className={cls.BalanceBtn} onClick={handler}>
      <IconDollar width='14' height='14' fill='var(--color-main)' />
      Пополнить пробив({balance ?? 0}$)
    </div>
  )
}
