import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconInboxDownload = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          d='M20.94,13a3.256,3.256,0,0,1,.06.62V18a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V13.62A3.256,3.256,0,0,1,3.06,13H6.95a2.89,2.89,0,0,1,2.46,1.52,3.006,3.006,0,0,0,5.18,0A2.887,2.887,0,0,1,17.04,13Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M7.978,6C6,6,5.693,6.715,5.221,7.818L3.243,12.434A3,3,0,0,0,3,13.616V18a2.652,2.652,0,0,0,3,3H18a2.652,2.652,0,0,0,3-3V13.616a3,3,0,0,0-.243-1.182L18.779,7.818C18.307,6.715,18,6,16.022,6Z'
        />
        <path
          fill={fill}
          d='M14.53,7.47a.749.749,0,0,0-1.06,0l-.72.719V2a.75.75,0,0,0-1.5,0V8.189l-.72-.719A.75.75,0,0,0,9.47,8.53l2,2a.751.751,0,0,0,1.06,0l2-2A.749.749,0,0,0,14.53,7.47Z'
        />
      </g>
    </svg>
  )
}

export default IconInboxDownload
