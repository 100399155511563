import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconCalendar = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M3,18a2.652,2.652,0,0,0,3,3H18a2.652,2.652,0,0,0,3-3V9H3Z'
        />
        <path
          fill={fill}
          d='M18,4.5H16.75V3a.75.75,0,0,0-1.5,0V4.5H8.75V3a.75.75,0,0,0-1.5,0V4.5H6a2.652,2.652,0,0,0-3,3V9H21V7.5A2.652,2.652,0,0,0,18,4.5Z'
        />
        <path
          fill={fill}
          d='M8.02,14a1,1,0,0,1-.01-2h.01a1,1,0,0,1,0,2Zm5-1a1,1,0,0,0-1-1h-.01a1,1,0,1,0,1.01,1Zm4,0a1,1,0,0,0-1-1h-.01a1,1,0,1,0,1.01,1Zm-8,4a1,1,0,0,0-1-1H8.01a1,1,0,1,0,1.01,1Zm4,0a1,1,0,0,0-1-1h-.01a1,1,0,1,0,1.01,1Zm4,0a1,1,0,0,0-1-1h-.01a1,1,0,1,0,1.01,1Z'
        />
      </g>
    </svg>
  )
}

export default IconCalendar
