import { setTestCount } from './reducer'
import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { ErrorMessages } from '../../constants/errors'
import { useMessage } from '../../hooks/useMessage'
import { serverPath } from '../../constants/api-endpoints'
import { errorMapper } from '../../utils/errorMapper'
import { IManagerTestsList } from '../../pages/ManagerTests/interfaces/manager.test.interfaces'

export const getTestsCount = () => async (dispatch: AppDispatch) => {
  const { error } = useMessage()
  try {
    const { data }: AxiosResponse<IManagerTestsList[]> = await axiosInstance.get<
      IManagerTestsList[]
    >(serverPath.QUESTIONNAIRE_MANAGER)

    dispatch(
      setTestCount({
        count: data.filter((el) => !el.isConfirm).length,
      }),
    )
  } catch (e) {
    error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
  }
}
