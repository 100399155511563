import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconSackDollar = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          d='M16.59,4.51,15.36,6.57H8.64L7.41,4.51A1,1,0,0,1,8.27,3h7.46A1,1,0,0,1,16.59,4.51Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M20,15c0,2-1,6-8,6s-8-4-8-6c0-4,3-7,4.64-8.43h6.72C17,8,20,11,20,15Z'
        />
        <path
          fill={fill}
          d='M14.5,15.613a2.244,2.244,0,0,0-1.682-2.18l-1.265-.319a.728.728,0,0,1-.4-.267.751.751,0,0,1-.153-.46.737.737,0,0,1,.725-.748h.55a.735.735,0,0,1,.721.666.75.75,0,0,0,1.491-.165A2.236,2.236,0,0,0,12.75,10.2V10a.75.75,0,0,0-1.5,0v.193a2.251,2.251,0,0,0-.068,4.374l1.265.319a.745.745,0,0,1,.553.727.753.753,0,0,1-.218.532.7.7,0,0,1-.507.216h-.55A.735.735,0,0,1,11,15.7a.75.75,0,1,0-1.491.165,2.236,2.236,0,0,0,1.737,1.945V18a.75.75,0,0,0,1.5,0v-.193a2.2,2.2,0,0,0,1.1-.61A2.24,2.24,0,0,0,14.5,15.613Z'
        />
      </g>
    </svg>
  )
}

export default IconSackDollar
