import React, { useCallback } from 'react'
import { ITransaction } from '../payment.interface'
import styles from '../PayModal.module.scss'
import { Form, Input } from 'antd'
import ButtonUi from '../../../ui/ButtonUi/ButtonUi'
import axiosInstance from '../../../../config/axios'
import { serverPath } from '../../../../constants/api-endpoints'
import { errorMapper } from '../../../../utils/errorMapper'
import { ErrorMessages } from '../../../../constants/errors'
import { useMessage } from '../../../../hooks/useMessage'
import WithLabel from '../../../ui/WithLabel/WithLabel'
import { IconDollar, IconInfoCircle } from '../../../../utils/icons'
import BadgeUi from '../../../ui/BadgeUi/BadgeUi'
import { useCrmSelector, useSubscriptionSelector } from '../../../../store/selectors/auth'
import { SubscriptionStatus } from '../../../../interfaces/subscriptions'
import { useAppDispatch } from '../../../../store/hooks'
import { getSubscription } from '../../../../store/subscriptions/thunk'

interface Props {
  setTransaction: React.Dispatch<React.SetStateAction<ITransaction | undefined>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const AddTransaction: React.FC<Props> = ({ setTransaction, setLoading }) => {
  const { success, error } = useMessage()
  const dispatch = useAppDispatch()

  const { data: subscriptions } = useSubscriptionSelector()

  const crm = useCrmSelector()

  const submit = useCallback(async (e: { amount: number }) => {
    setLoading(true)
    axiosInstance
      .post<ITransaction>(serverPath.BILLING, { amount: Number(e.amount) })
      .then(({ data }) => {
        setTransaction(data)
        success('Заявка добавлена')
      })
      .catch((e) => {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
      })
      .finally(() => setLoading(false))
  }, [])

  const deptHandler = async () => {
    await axiosInstance.post(serverPath.SUBSCRIPTIONS_IN_DEBT, { days: 1 })
    await dispatch(getSubscription())
    success('Кредит 1 день оформлен')
  }

  const price = crm?.price || subscriptions?.pending?.plan?.price || subscriptions?.active?.plan?.price;

  return (
    <Form name='add_transaction' onFinish={submit} className={styles.addWrapper}>
      <div className={styles.header}>Создать заявку на оплату:</div>
      <Form.Item
        className={styles.input}
        name={'amount'}
        rules={[{ required: true, message: 'Введите сумму' }]}
      >
        <WithLabel
          label={'Введите сумму USDT (TRC20):'}
          icon={<IconDollar width='18' height='18' fill='var(--color-main)' />}
        >
          <Input placeholder={`Введите сумму`} type='number' min={1} />
        </WithLabel>
      </Form.Item>

      <BadgeUi color='blue'>
        <IconInfoCircle width='20' height='20' fill='var(--color-blue)' />* Минимальная сумма
        пополнения {price} долларов.
        {Boolean(crm?.price) && (
          <>
            <br />
            &#160;&#160;&#160;Ваш месячный тариф: {crm?.price} usdt
          </>
        )}
          {Boolean(!crm?.price && subscriptions?.active?.plan?.price) && (
              <>
                  <br />
                  &#160;&#160;&#160;Ваш месячный тариф: {subscriptions?.active?.plan?.price} usdt
              </>
          )}
      </BadgeUi>

      <div className={styles.actions}>
        {(!subscriptions?.active ||
          subscriptions?.active?.status === SubscriptionStatus.FINSHED) && (
          <ButtonUi color={'red'} onClick={deptHandler}>
            Взять в кредит 1 день
          </ButtonUi>
        )}
        <ButtonUi htmlType='submit'>Cоздать заявку</ButtonUi>
      </div>
    </Form>
  )
}

export default AddTransaction
