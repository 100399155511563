import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
  onClick?: () => void
}

const IconTrash = ({ width = '24px', height = '24px', fill = '#000', onClick }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      onClick={onClick}
    >
      <g>
        <path
          fill={fill}
          d='M20,5.25H15.786a4.529,4.529,0,0,1-.242-.618l-.2-.607A1.5,1.5,0,0,0,13.919,3H10.081A1.5,1.5,0,0,0,8.658,4.025l-.2.607a4.529,4.529,0,0,1-.242.618H4a.75.75,0,0,0,0,1.5H20a.75.75,0,0,0,0-1.5Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M18.95,6.75,18.19,18.2a2.718,2.718,0,0,1-3,2.8H8.81a2.718,2.718,0,0,1-3-2.8L5.05,6.75Z'
        />
        <path
          fill={fill}
          d='M13.061,14l1.469-1.47a.75.75,0,0,0-1.06-1.06L12,12.939,10.53,11.47a.75.75,0,0,0-1.06,1.06L10.939,14,9.47,15.47a.75.75,0,1,0,1.06,1.06L12,15.061l1.47,1.469a.75.75,0,0,0,1.06-1.06Z'
        />
      </g>
    </svg>
  )
}

export default IconTrash
