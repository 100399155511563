import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconAnglesLeft = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          d='M12,20a1,1,0,0,1-.707-.293l-7-7a1,1,0,0,1,0-1.414l7-7a1,1,0,1,1,1.414,1.414L6.414,12l6.293,6.293A1,1,0,0,1,12,20Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M19,20a1,1,0,0,1-.707-.293l-7-7a1,1,0,0,1,0-1.414l7-7a1,1,0,1,1,1.414,1.414L13.414,12l6.293,6.293A1,1,0,0,1,19,20Z'
        />
      </g>
    </svg>
  )
}

export default IconAnglesLeft
