import { notification } from 'antd'

interface IUseMessage {
  success(msg: string, title?: string, duration?: number): void
  error(msg: string, title?: string, duration?: number): void
  warn(msg: string, title?: string, duration?: number): void
}

export const useMessage = (): IUseMessage => {
  notification.config({
    placement: 'bottomLeft',
    getContainer: () => document.getElementById('App') || document.body,
  })
  const success = (msg?: string, title?: string, duration?: number) => {
    notification.success({
      message: title ?? 'Выполнено',
      description: msg ?? '',
      duration: duration ?? 3,
    })
  }

  const error = (msg: string, title?: string, duration?: number) => {
    notification.error({
      message: title ?? 'Возникла ошибка',
      description: msg ?? '',
      duration: duration ?? 5,
    })
  }

  const warn = (msg: string, title?: string, duration?: number) => {
    notification.error({
      message: title ?? 'Внимание',
      description: msg ?? '',
      duration: duration ?? 5,
    })
  }

  return { success, error, warn }
}
