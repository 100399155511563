import { createSlice, Draft } from '@reduxjs/toolkit'

export interface ICurrency {
  btc: number
  usd: number
  updateTime: number
}

const initialState: ICurrency = {
  btc: 0,
  usd: 0,
  updateTime: 0,
}

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setBtc: (state: Draft<ICurrency>, { payload }) => {
      state.btc = payload
    },
    setUsd: (state: Draft<ICurrency>, { payload }) => {
      state.usd = payload
    },
    setUpdateTime: (state: Draft<ICurrency>, { payload }) => {
      state.updateTime = payload
    },
  },
})

export const { setBtc, setUsd, setUpdateTime } = currencySlice.actions

export default currencySlice.reducer
