import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { RefreshTokenResponseDto } from '../../dto/login/user.response.dto'
import { IUser } from '../../interfaces/user.interface'

export interface ITests {
  count: number
}

export const initialTestsState: ITests = {
  count: 0,
}

export const testsSlice = createSlice({
  name: 'tests',
  initialState: initialTestsState,
  reducers: {
    setTestCount: (state: Draft<ITests>, { payload }) => {
      state.count = payload.count
    },
    subtractTestsCount: (state) => {
      state.count = state.count - 1
    },
  },
})

export const { setTestCount, subtractTestsCount } = testsSlice.actions

export default testsSlice.reducer
