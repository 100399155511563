import React, { useEffect, useMemo } from 'react'
import { useNotificationsSelector } from '../../../store/selectors/notifications'
import { useModalContext } from '../../../context/modalContext'
import styles from './NotificationModal.module.scss'
import ButtonUi from '../../ui/ButtonUi/ButtonUi'
import { useDispatch } from 'react-redux'
import { readNotification } from '../../../store/notifications/thunks'
import { useAppDispatch } from '../../../store/hooks'

interface Props {
  id: string
}

const NotificationModal: React.FC<Props> = ({ id }) => {
  const { msg } = useNotificationsSelector()
  const { setModalComponent } = useModalContext()
  const dispatch = useAppDispatch()
  const message = useMemo(() => msg.find((el) => el.id === id), [id])

  useEffect(() => {
    dispatch(readNotification(id)).then()
  }, [id])

  return (
    <div className={styles.container}>
      {message ? (
        <>
          <div className={styles.title}>{message.title}</div>
          <div className={styles.desc}>{message.description}</div>
          <div className={styles.actions}>
            <ButtonUi color='red' onClick={() => setModalComponent({ component: null })}>Закрыть</ButtonUi>
          </div>
        </>
      ) : (
        <div className={styles.empty}>Сообщение не найдено</div>
      )}
    </div>
  )
}

export default NotificationModal
