import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconClipboardCheck = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M16,5h1a2.652,2.652,0,0,1,3,3V18a2.652,2.652,0,0,1-3,3H7a2.652,2.652,0,0,1-3-3V8A2.652,2.652,0,0,1,7,5H8'
        />
        <path
          fill={fill}
          d='M16,4.5v1A1.326,1.326,0,0,1,14.5,7h-5A1.326,1.326,0,0,1,8,5.5v-1A1.326,1.326,0,0,1,9.5,3h5A1.326,1.326,0,0,1,16,4.5Z'
        />
        <path
          fill={fill}
          d='M11.167,15.75a.744.744,0,0,1-.53-.22L8.97,13.864A.75.75,0,1,1,10.03,12.8l1.137,1.136,2.8-2.8A.75.75,0,0,1,15.03,12.2L11.7,15.53A.744.744,0,0,1,11.167,15.75Z'
        />
      </g>
    </svg>
  )
}

export default IconClipboardCheck
