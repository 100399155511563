import React from 'react'
import { IRoute } from '../interfaces/router/route.interface'
import { routersPath } from './routes-path'
import LoginPage from '../pages/LoginPage/Login'

export const publicRoutes: IRoute[] = [
  {
    path: routersPath.LOGIN_PAGE,
    component: <LoginPage />,
    title: 'Логин',
    show: true,
  },
]
