import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconRotateCircle = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} opacity={0.3} cx='12' cy='12' r='10' />
        <path
          fill={fill}
          d='M17.07,13.307a5.223,5.223,0,0,1-8.82,2.349V16a.75.75,0,0,1-1.5,0V13.5a.75.75,0,0,1,.75-.75H10a.75.75,0,0,1,0,1.5H9.005a3.735,3.735,0,0,0,6.61-1.307.75.75,0,1,1,1.455.364ZM16.5,7.25a.75.75,0,0,0-.75.75v.344a5.223,5.223,0,0,0-8.82,2.349.75.75,0,1,0,1.455.364A3.735,3.735,0,0,1,15,9.75H14a.75.75,0,0,0,0,1.5h2.5a.75.75,0,0,0,.75-.75V8A.75.75,0,0,0,16.5,7.25Z'
        />
      </g>
    </svg>
  )
}

export default IconRotateCircle
