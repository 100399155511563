import React from 'react'
import { Button, ButtonProps } from 'antd'
import cn from 'classnames'
import './ButtonUi.scss'

interface Props extends Omit<ButtonProps, 'color'> {
  color?: string
  hexBg?: string
  hexFont?: string
  outlined?: boolean
  highlighted?: boolean
}

const ButtonUi: React.FC<Props> = ({
  outlined,
  color = 'main',
  hexBg,
  hexFont,
  className,
  children,
  highlighted,
  ...props
}) => {
  return (
    <Button
      {...props}
      className={cn(
        'buttonUi',
        {
          outlined: outlined,
        },
        `buttonUi_${color}`,
        { ['buttonUi_highlighted']: highlighted },
        className,
      )}
      style={{ background: hexBg, color: hexFont }}
    >
      {children}
    </Button>
  )
}

export default ButtonUi
