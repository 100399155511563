import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
  transform?: string
}

const IconCaretRightCircle = ({
  width = '24px',
  height = '24px',
  fill = '#000',
  transform = 'rotate(0)',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      transform={transform}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g>
        <circle fill={fill} opacity={0.3} cx='12' cy='12' r='10' />
        <path
          fill={fill}
          d='M14.764,11.132,11.3,8.212A.894.894,0,0,0,9.833,8.9v6.2a.894.894,0,0,0,1.469.689l3.462-2.92A1.137,1.137,0,0,0,14.764,11.132Z'
        />
      </g>
    </svg>
  )
}

export default IconCaretRightCircle
