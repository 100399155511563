import React, { ReactNode } from 'react'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input/Input'
import styles from './InputWithLabel.module.scss'
import cn from 'classnames'

interface Props extends InputProps {
  label?: string | ReactNode
  required?: boolean
  error?: string | null
  icon?: JSX.Element
  fullWidth?: boolean
}

const InputWithLabel: React.FC<Props> = ({
  label,
  required,
  error,
  className,
  fullWidth,
  icon,
  ...props
}) => {
  return (
    <div className={cn(styles.container, { [styles.fullWidth]: fullWidth })}>
      <span className={styles.labelWrapper}>
        {label && (
          <p className={styles.label}>
            <>
              {icon && icon}
              {label && label}
              {required && <span>*</span>}
            </>
          </p>
        )}
        {error && <p className={styles.error}>{error}</p>}
      </span>

      <Input className={cn(className, styles.input)} {...props} />
    </div>
  )
}

export default InputWithLabel
