import React from 'react'
import styles from './PayBtn.module.scss'
import { useModalContext } from '../../../context/modalContext'
import PayModal from '../../Modals/PayModal/PayModal'
import { IconDollar } from '../../../utils/icons'
import { useEndSubscription } from '../../../store/selectors/crmInfo'
import cn from 'classnames'

const PayBtn: React.FC = () => {
  const { setModalComponent } = useModalContext()
  const isEndSubscription = useEndSubscription()

  const handler = () => {
    setModalComponent({
      component: <PayModal />,
      maskClosable: true,
    })
  }
  return (
    <div className={cn(styles.container, { [styles.red]: isEndSubscription })} onClick={handler}>
      <IconDollar width='14' height='14' fill='var(--color-main)' />
      Пополнить CRM
    </div>
  )
}

export default PayBtn
