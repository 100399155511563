import React, { useCallback, useState } from 'react'
import { Form } from 'antd'
import { GetManagerDto } from '../../../dto/user/user.dto'
import { useModalContext } from '../../../context/modalContext'
import { useMessage } from '../../../hooks/useMessage'
import axiosInstance from '../../../config/axios'
import { serverPath } from '../../../constants/api-endpoints'
import { errorMapper } from '../../../utils/errorMapper'
import { ErrorMessages } from '../../../constants/errors'
import styles from './ManagerPageUpdate.module.scss'
import ButtonUi from '../../ui/ButtonUi/ButtonUi'
import InputWithLabel from '../../ui/InputWithLabel/InputWithLable'
import { EUserRoles } from '../../../enums/roles'
import BadgeUi from '../../ui/BadgeUi/BadgeUi'
import { IconInfoCircle } from '../../../utils/icons'

interface IUpdate {
  login: string
  name: string
  password?: string
  nick: string
}

interface Props {
  manager: Omit<GetManagerDto, 'lastOnline' | 'password'>
  update: () => void
}

const ManagerUpdateModal: React.FC<Props> = ({ manager, update }) => {
  const { setModalComponent } = useModalContext()
  const { error, success } = useMessage()
  const [disable, setDisable] = useState(false)
  const updateHandler = useCallback(async (e: IUpdate) => {
    try {
      setDisable(true)
      await axiosInstance.put(serverPath.USERS + '/' + manager.id, { ...e })
      success('Пользователь обновлен успешно')
      update()
      setModalComponent({ component: null })
    } catch (e) {
      error(errorMapper(ErrorMessages.UPDATE_DATA, e), 'Ошибка обновления пользователя')
    } finally {
      setDisable(false)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>Редактировать менеджера</span>
      </div>
      <Form name='basic' onFinish={updateHandler}>
        <Form.Item
          className={styles.item}
          initialValue={manager?.login || manager?.email}
          rules={[
            { required: true, message: 'Введите логин' },
            { min: 3, message: 'Минимальная длинна 3 символа' },
          ]}
          validateTrigger='onSubmit'
          name='login'
        >
          <InputWithLabel
            placeholder='Логин'
            label='Логин:'
            // disabled={manager.role === EUserRoles.ADMIN}
          />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  className={styles.item}*/}
        {/*  initialValue={manager.name}*/}
        {/*  rules={[*/}
        {/*    { required: true, message: 'введите имя' },*/}
        {/*    { min: 3, message: 'минимальная длинна 3 символа' },*/}
        {/*  ]}*/}
        {/*  name='name'*/}
        {/*>*/}
        {/*  <InputWithLabel placeholder='Имя' label='Имя:' />*/}
        {/*</Form.Item>*/}
        <Form.Item
          className={styles.itemPass}
          rules={[
            { max: 10, message: 'Длинна не больше 10 символов' },
            { min: 3, message: 'Минимальная длинна 3 символа' },
          ]}
          validateTrigger='onSubmit'
          name='password'
        >
          <InputWithLabel placeholder='Пароль' label='Пароль:' />
        </Form.Item>
        <BadgeUi color='red' className={styles.badg}>
          <IconInfoCircle width='20' height='20' fill='var(--color-red)' />
          Если не собираетесь менять пароль - оставьте поле пустым
        </BadgeUi>
        <Form.Item
          className={styles.item}
          initialValue={manager?.nickName}
          rules={[
            { required: true, message: 'Введите ник' },
            { min: 2, message: 'Минимальная длинна 2 символа' },
          ]}
          validateTrigger='onSubmit'
          name='nickName'
        >
          <InputWithLabel
            placeholder='Отображать как'
            label='Отображать как:'
            // disabled={manager.role === EUserRoles.ADMIN}
          />
        </Form.Item>
        <div className={styles.actions}>
          <ButtonUi color='red' onClick={() => setModalComponent({ component: null })}>
            Отмена
          </ButtonUi>
          <ButtonUi className={styles.submit} type='primary' htmlType='submit' disabled={disable}>
            Обновить
          </ButtonUi>
        </div>
      </Form>
    </div>
  )
}

export default ManagerUpdateModal
