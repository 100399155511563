import React, { useMemo } from 'react'
import { EUserRoles } from '../../enums/roles'
import AdminHeader from './AdminHeader/AdminHeader'
import ManagerHeader from './ManagerHeader/ManagerHeader'
import { useUserSelector } from '../../store/selectors/auth'

const useHeader = () => {
  const user = useUserSelector()
  const header = useMemo(() => {
    switch (user?.role) {
      case EUserRoles.ADMIN: {
        return <AdminHeader />
      }
      case EUserRoles.OPERATOR: {
        return <AdminHeader />
      }
      case EUserRoles.MANAGER: {
        return <ManagerHeader />
      }
      default:
        return <ManagerHeader />
    }
  }, [user])
  return header
}

export default useHeader
