import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconEye = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M20.592,13.477a2.877,2.877,0,0,0,0-2.954C19.365,8.469,16.61,5,12,5S4.635,8.469,3.408,10.523a2.877,2.877,0,0,0,0,2.954C4.635,15.531,7.39,19,12,19S19.365,15.531,20.592,13.477Z'
        />
        <path
          fill={fill}
          d='M12,8.5a3.479,3.479,0,0,0-1.045.176,1.631,1.631,0,0,1-2.279,2.28A3.49,3.49,0,1,0,12,8.5Z'
        />
      </g>
    </svg>
  )
}

export default IconEye
