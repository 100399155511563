import { createContext, useContext } from 'react'
import { IModal } from '../router/RouterComponent'

const contextInitial = {
  setModalComponent: () => {
    return
  },
}

export interface IModalContext {
  setModalComponent(val: IModal): void
}

export const ModalContext = createContext<IModalContext>(contextInitial)
export const useModalContext = (): IModalContext => useContext(ModalContext)
