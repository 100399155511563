import React, { useEffect, useMemo, useState } from 'react'
import styles from './SubscriptionModal.module.scss'
import { subscriptionMapper } from '../../../utils/mappers'
import { subscriptionList } from './subscriptionList'
import cn from 'classnames'
import ButtonUi from '../../ui/ButtonUi/ButtonUi'
import { useModalContext } from '../../../context/modalContext'
import axiosInstance from '../../../config/axios'
import { serverPath } from '../../../constants/api-endpoints'
import { errorMapper } from '../../../utils/errorMapper'
import { ErrorMessages } from '../../../constants/errors'
import { IBalanceReq, IPlanReq } from './interfaces'
import { useMessage } from '../../../hooks/useMessage'
import { useSubscriptionSelector } from '../../../store/selectors/auth'
import { useAppDispatch } from '../../../store/hooks'
import { getSubscription } from '../../../store/subscriptions/thunk'
import { shortDateFormat } from '../../../utils/time'
import { CloseOutlined } from '@ant-design/icons'
import TooltipUi from "../../ui/TooltipUi/TooltipUi";
import {IconLinkAlt} from "../../../utils/icons";

const SubscriptionModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { data: subscriptions } = useSubscriptionSelector()
  const [selected, setSelected] = useState<string>()
  const { setModalComponent } = useModalContext()
  const { error, success } = useMessage()
  const [currentBalance, setCurrentBalance] = useState(0)
  const [plans, setPlans] = useState<IPlanReq[]>()
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (plans && selected && subscriptions) {
      const newPlan = plans.find((el) => el.id === selected)
      if (newPlan) {
        if (currentBalance < newPlan.price) {
          setErrorMsg(
            `Недостаточно средств, пополните баланс минимум на ${
              newPlan.price - currentBalance
            } USDT`,
          )
          return
        }
      }
    }
    setErrorMsg('')
  }, [plans, selected, subscriptions, currentBalance])

  useEffect(() => {
    if (subscriptions?.active?.plan?.id) {
      setSelected(subscriptions?.active.plan?.id)
    }
  }, [subscriptions])

  const getBalance = async () => {
    try {
      const response = await axiosInstance.get<IBalanceReq>(serverPath.BALANCE)
      setCurrentBalance(response?.data.balance ?? 0)
    } catch (e) {
      error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
    }
  }

  useEffect(() => {
    getBalance().then()
    axiosInstance
      .get<IPlanReq[]>(serverPath.PLANS)
      .then((response) => {
        setPlans(response.data)
      })
      .catch((e) => error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR))
  }, [])

  const handler = (val: string) => {
    setSelected(val)
  }

  const closeHandler = async () => {
    setModalComponent({ component: null })
  }

  const handlerDelete = async () => {
    if (subscriptions?.pending) {
      try {
        await axiosInstance.delete(serverPath.SUBSCRIBE + '/' + subscriptions?.pending?.id)
        await dispatch(getSubscription())
        success('Тариф удален')
      } catch (e) {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
      }
    }
  }

  const handlerSubmit = async () => {
    try {
      await axiosInstance.patch(serverPath.SUBSCRIPTIONS, { planId: selected })
      await getBalance()
      await dispatch(getSubscription())
      setModalComponent({ component: null })
      success('Тариф обновлен')
    } catch (e) {
      error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.UPDATE_DATA)
    }
  }

  const isSubmitEnabled = useMemo(() => {
    if (selected === 'debt') {
      return true
    }
    if (selected && plans) {
      const price = plans?.find((el) => el.id === selected)?.price
      if (price && currentBalance) {
        return currentBalance >= price
      }
      return false
    }
    return false
  }, [selected, subscriptions, plans, currentBalance])

  return (
    <div className={styles.container}>
      <div className={styles.header}>Тарифы</div>
      <div className={styles.current}>
        <div className={styles.currentRow}>
          <span className={styles.title}>Текущий тариф: </span>
          <span className={styles.desc}>
            {subscriptionMapper(subscriptions?.active?.plan?.type)}
          </span>
        </div>
        {subscriptions?.pending && (
          <div className={styles.currentRow}>
            <span className={styles.title}>Следующий тариф: </span>
            <span className={styles.desc}>
              {subscriptionMapper(subscriptions?.pending?.plan?.type)}
            </span>
            (начало: {shortDateFormat(subscriptions?.pending?.startDate)})
            <CloseOutlined onClick={handlerDelete} style={{ color: 'red' }} />
          </div>
        )}
        <div className={styles.currentRow}>
          <span className={styles.title}>Баланс: </span>
          <span className={styles.desc}>{currentBalance} USDT</span>
        </div>
        <div className={styles.currentRow}>
          <span className={styles.title}>Дата окончания: </span>
          <span className={styles.desc}>
            {subscriptions?.active?.endDate
              ? shortDateFormat(subscriptions?.active?.endDate)
              : 'Не установлена'}
          </span>
        </div>
      </div>
      <div className={styles.listContainer}>
        <div className={styles.listTitle}>Доступные тарифы:</div>
        {plans && plans?.length > 0 ? (
          <div className={styles.list}>
            {plans.map((el) => (
              <div
                key={el.id}
                className={cn(styles.listItem, { [styles.active]: el.id === selected })}
                onClick={() => handler(el.id)}
              >
                <div className={styles.item}>
                  <div className={styles.itemName}>{subscriptionMapper(el.type)}</div>
                  <div className={styles.itemDesc}>
                    {subscriptionList.find((r) => r.type === el.type)?.desc ?? ''}
                  </div>
                </div>
                <div className={styles.price}>
                  <div className={styles.priceTitle}>
                    {el.price} <small>USDT</small>
                  </div>
                  <span>месяц</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.empty}>нет планов</div>
        )}
      </div>
      <div className={styles.error}>{errorMsg}</div>
      <div className={styles.actions}>
        <ButtonUi color={'red'} onClick={closeHandler}>
          Отменить
        </ButtonUi>
        <ButtonUi color={'green'} disabled={!isSubmitEnabled} onClick={handlerSubmit}>
          Обновить
        </ButtonUi>
      </div>
    </div>
  )
}

export default SubscriptionModal
