import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconKey = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M10,18H7v3H3V17l6.26-6.26A5.861,5.861,0,0,1,9,9a6,6,0,1,1,6,6,5.861,5.861,0,0,1-1.74-.26Z'
        />
        <path fill={fill} d='M16.02,9.25a1.25,1.25,0,0,1-.01-2.5h.01a1.25,1.25,0,0,1,0,2.5Z' />
      </g>
    </svg>
  )
}

export default IconKey
