import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconLocationPin = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M12,2.5A8.51,8.51,0,0,0,3.5,11c0,4.983,4.629,8.041,7.692,10.064l.531.352a.5.5,0,0,0,.554,0l.531-.352C15.871,19.041,20.5,15.983,20.5,11A8.51,8.51,0,0,0,12,2.5Z'
        />
        <circle fill={fill} cx='12' cy='11' r='2.5' />
      </g>
    </svg>
  )
}

export default IconLocationPin
