import { initialAuthState, setLoading, setUser } from './reducer'
import { AppDispatch } from '../index'
import { appHistory } from '../../router/history'
import { getDefaultPathByRole, routersPath } from '../../router/routes-path'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { ErrorMessages } from '../../constants/errors'
import { LoginRequestDto } from '../../dto/login/login.request.dto'
import { LoginResponseDto } from '../../dto/login/user.response.dto'
import { useMessage } from '../../hooks/useMessage'
import { serverPath } from '../../constants/api-endpoints'
import { errorMapper } from '../../utils/errorMapper'
import { getCountries, getFields, getSettings } from '../settings/thunk'
import { getCrmInfo, getPhoneBalance } from '../crmInfo/thunk'
import { clearCrmInfo } from '../crmInfo/reducer'
import { getSubscription } from '../subscriptions/thunk'
import { getUserPermissions } from '../userPermissions/thunk'

const initAppState = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true))
  await dispatch(getSettings())
  await dispatch(getSubscription())
  await dispatch(getCrmInfo())
  await dispatch(getUserPermissions())
  await dispatch(getCountries())
  await dispatch(getFields())
  await dispatch(getPhoneBalance())
  dispatch(setLoading(false))
}

export const authLogin =
  ({ login, password }: LoginRequestDto) =>
  async (dispatch: AppDispatch) => {
    const { error } = useMessage()
    try {
      dispatch(setLoading(true))

      const {
        data: { accessToken, refreshToken, isActive, ...user },
      }: AxiosResponse<LoginResponseDto> = await axiosInstance.post(serverPath.LOGIN, {
        login,
        password,
      })

      if (user?.crm && !user?.crm.isActive) {
        dispatch(setUser(initialAuthState))
        appHistory.push(routersPath.DISABLE_PAGE)
      } else {
        // dispatch(getCrmInfo()).then()
        // dispatch(getUserPermissions()).then()
        dispatch(
          setUser({
            isActive: isActive ?? true,
            isAuth: true,
            accessToken,
            refreshToken,
            user,
          }),
        )
        await dispatch(initAppState())
        // await dispatch(getSettings())
        // await dispatch(getSubscription())
        appHistory.push(getDefaultPathByRole(user.role))
      }
    } catch (e) {
      error(errorMapper(ErrorMessages.AUTH_FAILED, e))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const authLogout = () => (dispatch: AppDispatch) => {
  try {
    appHistory.push(routersPath.LOGIN_PAGE)
    dispatch(setUser(initialAuthState))
    dispatch(clearCrmInfo())
  } catch (e) {
    throw new Error('Logout failed')
  }
}

// export const updateProfile = (body: IUser) => async (dispatch: AppDispatch, getState: any) => {
//   const {
//     auth: {
//       user: { id },
//     },
//   } = getState()
//   try {
//     const { data }: AxiosResponse = await axiosInstance.patch(`${MANAGER_UPDATE}${id}`, body)
//     dispatch(profileUpdate(data))
//     dispatch(closeModal())
//     dispatch(showSnackbar(ResponseMessage.UPDATED_SUCCESS, 'success'))
//   } catch (e) {
//     dispatch(showSnackbar(ErrorMessages.UPDATE_DATA, 'error'))
//   }
// }
