import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconSearchText = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          d='M21.72,21.526a.748.748,0,0,1-1.06,0l-4.37-4.37a7.585,7.585,0,0,0,1.06-1.06l4.37,4.37A.754.754,0,0,1,21.72,21.526Z'
        />
        <circle fill={fill} opacity={0.3} cx='11.19' cy='10.996' r='8' />
        <path
          fill={fill}
          d='M14.75,9.5a.75.75,0,0,1-.75.75H8a.75.75,0,0,1,0-1.5h6A.75.75,0,0,1,14.75,9.5ZM12,11.75H8a.75.75,0,0,0,0,1.5h4a.75.75,0,0,0,0-1.5Z'
        />
      </g>
    </svg>
  )
}

export default IconSearchText
