import React, { DetailedHTMLProps } from 'react'
import styles from './BoxUi.module.scss'
import { IPropsWithChild } from '../../layout/GenLayout/GenLayout'
import cn from 'classnames'

type Props = IPropsWithChild &
  DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const BoxUi: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <div className={cn(styles.container, className)} {...props}>
      {children}
    </div>
  )
}

export default BoxUi
