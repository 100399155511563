import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconAngleLeftCircle = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} opacity={0.3} cx='12' cy='12' r='10' />
        <path
          fill={fill}
          d='M13,15.75a.744.744,0,0,1-.53-.22l-3-3a.749.749,0,0,1,0-1.06l3-3a.75.75,0,0,1,1.06,1.06L11.061,12l2.469,2.47A.75.75,0,0,1,13,15.75Z'
        />
      </g>
    </svg>
  )
}

export default IconAngleLeftCircle
