import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconPowerOff = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M3,17.625V6.375Q3,3,6.375,3h11.25Q21,3,21,6.375v11.25Q21,21,17.625,21H6.375Q3,21,3,17.625Z'
        />
        <path
          fill={fill}
          d='M12,12.194a.75.75,0,0,1-.75-.75V7a.75.75,0,0,1,1.5,0v4.444A.75.75,0,0,1,12,12.194Zm5.194.362a5.165,5.165,0,0,0-1.526-3.673.75.75,0,0,0-1.061,1.06,3.694,3.694,0,1,1-5.214,0,.75.75,0,0,0-1.061-1.06,5.193,5.193,0,1,0,8.862,3.673Z'
        />
      </g>
    </svg>
  )
}

export default IconPowerOff
