import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFolderImport = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,10v7a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V7A2.652,2.652,0,0,1,6,4h4l3,3h5A2.652,2.652,0,0,1,21,10Z'
        />
        <path
          fill={fill}
          d='M12,13.25H8.811l.719-.72a.75.75,0,0,0-1.06-1.06l-2,2a.771.771,0,0,0-.162.244.751.751,0,0,0,.162.817l2,2a.75.75,0,0,0,1.06-1.06l-.719-.72H12a.75.75,0,0,0,0-1.5Z'
        />
      </g>
    </svg>
  )
}

export default IconFolderImport
