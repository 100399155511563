import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconLinkAlt = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          d='M10.88,14.12a1,1,0,0,1-.707-.293,4.914,4.914,0,0,1-.5-6.419,3.742,3.742,0,0,1,.5-.594l3.36-3.361a4.959,4.959,0,0,1,7.013,7.014l-1.84,1.84a1,1,0,1,1-1.414-1.414l1.84-1.84a2.965,2.965,0,0,0,0-4.186,3.03,3.03,0,0,0-4.185,0l-3.359,3.36a2.092,2.092,0,0,0-.268.318,2.943,2.943,0,0,0,.268,3.868,1,1,0,0,1-.707,1.707Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M6.961,22a4.96,4.96,0,0,1-3.506-8.467l1.84-1.84a1,1,0,1,1,1.414,1.414l-1.84,1.84a2.965,2.965,0,0,0,0,4.186,3.03,3.03,0,0,0,4.185,0l3.359-3.36a2.845,2.845,0,0,0,.689-1.09,2.932,2.932,0,0,0-.689-3.1,1,1,0,1,1,1.414-1.414,4.966,4.966,0,0,1,1.164,5.165,4.819,4.819,0,0,1-1.164,1.849l-3.359,3.36A4.93,4.93,0,0,1,6.961,22Z'
        />
      </g>
    </svg>
  )
}

export default IconLinkAlt
