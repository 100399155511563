import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { serverPath } from '../../constants/api-endpoints'
import { setSubscription } from './reducer'
import { ISubscriptionReq } from '../../interfaces/subscriptions'
import { setBillingDate } from '../crmInfo/reducer'

export const getSubscription = () => async (dispatch: AppDispatch) => {
  try {
    const { data: data }: AxiosResponse<ISubscriptionReq> = await axiosInstance.get(
      serverPath.SUBSCRIBE,
    )

    const { active, pending, billingDate } = data
    dispatch(setBillingDate(billingDate))

    dispatch(setSubscription({ data: { active, pending } }))
  } catch (e) {
    console.log(e)
  }
}
