import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { serverPath } from '../../constants/api-endpoints'
import { setUserPermissions } from './reducer'

export const getUserPermissions = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<{ permissions: { id: 'string'; name: 'string' }[] }> =
      await axiosInstance.get(serverPath.USER_PERMISSIONS)
    dispatch(setUserPermissions(data))
  } catch (e) {
    console.log(e)
  }
}
