import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { RefreshTokenResponseDto } from '../../dto/login/user.response.dto'
import { IUser } from '../../interfaces/user.interface'

export interface IAuth {
  isAuth: boolean
  accessToken: string
  refreshToken: string
  user: IUser | null
  isLoading: boolean
  isActive: boolean
}

export const initialAuthState: IAuth = {
  accessToken: '',
  refreshToken: '',
  isAuth: false,
  isActive: true,
  user: null,
  isLoading: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setUser: (state: Draft<IAuth>, { payload }) => {
      state.isAuth = payload.isAuth
      state.isActive = payload.isActive
      state.accessToken = payload.accessToken
      state.refreshToken = payload.refreshToken
      state.user = payload.user
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload.isLoading
    },
    profileUpdate: (state: Draft<IAuth>, { payload }) => {
      state.user = payload
    },
    updateTokens: (state: Draft<IAuth>, action: PayloadAction<RefreshTokenResponseDto>) => {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
  },
})

export const { setUser, setLoading, profileUpdate, updateTokens } = authSlice.actions

export default authSlice.reducer
