import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconHomeDash = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M19.513,18.948l1.455-7.982a2,2,0,0,0-.7-1.9L13.589,3.569a2.5,2.5,0,0,0-3.178,0L3.73,9.062a2,2,0,0,0-.7,1.9l1.455,7.982A2.5,2.5,0,0,0,6.947,21H17.053A2.5,2.5,0,0,0,19.513,18.948Z'
        />
        <path fill={fill} d='M14,17.75H10a.75.75,0,0,1,0-1.5h4a.75.75,0,0,1,0,1.5Z' />
      </g>
    </svg>
  )
}

export default IconHomeDash
