import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconUserViewfinder = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,8.75A.75.75,0,0,1,20.25,8V4.5c0-.589-.161-.75-.75-.75H16a.75.75,0,0,1,0-1.5h3.5A2.067,2.067,0,0,1,21.75,4.5V8A.75.75,0,0,1,21,8.75ZM3.75,8V4.5c0-.589.161-.75.75-.75H8a.75.75,0,0,0,0-1.5H4.5A2.067,2.067,0,0,0,2.25,4.5V8a.75.75,0,0,0,1.5,0Zm5,13A.75.75,0,0,0,8,20.25H4.5c-.589,0-.75-.161-.75-.75V16a.75.75,0,0,0-1.5,0v3.5A2.067,2.067,0,0,0,4.5,21.75H8A.75.75,0,0,0,8.75,21Zm13-1.5V16a.75.75,0,0,0-1.5,0v3.5c0,.589-.161.75-.75.75H16a.75.75,0,0,0,0,1.5h3.5A2.067,2.067,0,0,0,21.75,19.5Z'
        />
        <path
          fill={fill}
          d='M12.006,11.167A2.834,2.834,0,1,0,9.173,8.333,2.837,2.837,0,0,0,12.006,11.167Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M13.333,12.667H10.667a3.114,3.114,0,0,0-3.334,3.345,1.765,1.765,0,0,0,2,1.988h5.33a1.765,1.765,0,0,0,2-1.988A3.122,3.122,0,0,0,13.333,12.667Z'
        />
      </g>
    </svg>
  )
}

export default IconUserViewfinder
