import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconLogOut = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M15,17v1a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V6A2.652,2.652,0,0,1,6,3h6a2.652,2.652,0,0,1,3,3V7'
        />
        <path
          fill={fill}
          d='M21.692,11.713a.771.771,0,0,0-.162-.244l-3-3a.75.75,0,0,0-1.06,1.06l1.719,1.72H8a.75.75,0,0,0,0,1.5H19.189L17.47,14.47a.75.75,0,1,0,1.06,1.06l3-3a.771.771,0,0,0,.162-.244A.751.751,0,0,0,21.692,11.713Z'
        />
      </g>
    </svg>
  )
}

export default IconLogOut
