import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconPhoneCall = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M14.869,14.794l-1.115,1.665a11.6,11.6,0,0,1-6.211-6.226L9.2,9.128a2.051,2.051,0,0,0,.629-2.752L8.269,3.737a1.5,1.5,0,0,0-1.587-.708l-.016,0a4.526,4.526,0,0,0-3.507,5.6A17.441,17.441,0,0,0,15.373,20.842a4.527,4.527,0,0,0,5.6-3.509h0a1.5,1.5,0,0,0-.7-1.583l-2.642-1.576A2.052,2.052,0,0,0,14.869,14.794Z'
        />
        <path
          fill={fill}
          d='M17,10.75a.75.75,0,0,1-.75-.75A2.253,2.253,0,0,0,14,7.75a.75.75,0,0,1,0-1.5A3.755,3.755,0,0,1,17.75,10,.75.75,0,0,1,17,10.75ZM20.75,10A6.758,6.758,0,0,0,14,3.25a.75.75,0,0,0,0,1.5A5.256,5.256,0,0,1,19.25,10a.75.75,0,0,0,1.5,0Z'
        />
      </g>
    </svg>
  )
}

export default IconPhoneCall
