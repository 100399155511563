import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconEyeClosed = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M13.451,15.912a10.744,10.744,0,0,0,1.956-.424l.564,2.271a1,1,0,0,1-1.942.482ZM8.029,17.759a1,1,0,0,0,1.942.482l.578-2.329a10.744,10.744,0,0,1-1.956-.424ZM4.671,13.183l-1.5,2.263a1,1,0,0,0,1.666,1.108l1.418-2.135A10.021,10.021,0,0,1,4.671,13.183Zm13.078,1.236,1.418,2.135a1,1,0,1,0,1.666-1.108l-1.5-2.263A10.021,10.021,0,0,1,17.749,14.419Z'
        />
        <path
          fill={fill}
          d='M21.319,8.054a1,1,0,0,0-1.266.626,8.48,8.48,0,0,1-2.586,3.507l0,0A8.471,8.471,0,0,1,12,14a8.471,8.471,0,0,1-5.463-1.811l0,0A8.48,8.48,0,0,1,3.947,8.68a1,1,0,0,0-1.895.636,10.823,10.823,0,0,0,2.619,3.867,9.743,9.743,0,0,0,3.922,2.3,10.744,10.744,0,0,0,1.956.424,12.007,12.007,0,0,0,2.9,0,10.744,10.744,0,0,0,1.956-.424,9.743,9.743,0,0,0,3.922-2.3,10.823,10.823,0,0,0,2.619-3.867A1,1,0,0,0,21.319,8.054Z'
        />
      </g>
    </svg>
  )
}

export default IconEyeClosed
