import React from 'react'
import { Dropdown, MenuProps } from 'antd'
import './DropdownUi.scss'
import { DropdownProps } from 'antd/lib'

interface Props extends DropdownProps {
  children: JSX.Element
}

const DropdownUI: React.FC<Props> = ({ children, trigger = ['click'], ...props }) => {
  return (
    <Dropdown
      trigger={trigger}
      overlayClassName={'dropContainer'}
      getPopupContainer={() => document.getElementById('App') || document.body}
      {...props}
    >
      {children}
    </Dropdown>
  )
}

export default DropdownUI
