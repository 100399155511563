import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconSettings = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M8.19,18.069h0a3.011,3.011,0,0,0-3.015,0h0a.333.333,0,0,1-.455-.122L3.045,15.057a.333.333,0,0,1,.121-.454l.01,0a3,3,0,0,0,1.5-2.6h0a3.009,3.009,0,0,0-1.5-2.6l0,0a.33.33,0,0,1-.12-.452L4.718,6.056a.333.333,0,0,1,.453-.121h0a3.012,3.012,0,0,0,3.017,0l0,0A3,3,0,0,0,9.685,3.336h0A.337.337,0,0,1,10.022,3h3.956a.337.337,0,0,1,.337.336h0A3,3,0,0,0,15.809,5.93l0,0a3.012,3.012,0,0,0,3.017,0h0a.332.332,0,0,1,.453.122l1.673,2.886a.333.333,0,0,1-.121.453l-.008,0a3,3,0,0,0-1.5,2.6h0a3.009,3.009,0,0,0,1.5,2.6l0,0a.33.33,0,0,1,.12.452l-1.673,2.888a.333.333,0,0,1-.453.121h0a3.012,3.012,0,0,0-3.017,0h0a3,3,0,0,0-1.5,2.6h0a.336.336,0,0,1-.337.336H10.019a.335.335,0,0,1-.336-.336h0A3,3,0,0,0,8.19,18.069Z'
        />
        <circle fill={fill} cx='12.001' cy='12' r='3' />
      </g>
    </svg>
  )
}

export default IconSettings
