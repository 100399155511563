import React from 'react'
import { NavLink } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { Menu, Space } from 'antd'
import DropdownUI from '../../ui/DropDownUI/DropdownUi'

import {
  IconAngleDown,
  IconBoltSquareAlt,
  IconChartBar,
  IconClipboardEdit,
  IconDatabase,
  IconDollar,
  IconDollarReceiveSquare,
  IconDollarSendSquare,
  IconFolderAlt,
  IconFolderImport,
  IconFolderSearch,
  IconFolderShield,
  IconHomeDash,
  IconKey,
  IconPrint,
  IconReceiptText,
  IconSackDollar,
  IconSettings,
  IconUserEye,
  IconUsersGroup,
  IconClipboardCheck,
  IconInboxDownload,
} from '../../../utils/icons'
import { useCrmPermission, useEndSubscription } from '../../../store/selectors/crmInfo'
import { LinkOutlined } from '@ant-design/icons'

export interface ITabs {
  title: JSX.Element
  pages: routersPath[]
  key?: string
  show: boolean
}

export const AdminTabs = (): ITabs[] => {
  const permissions = useCrmPermission()
  const isEndSubscription = useEndSubscription()
  return [
    {
      show: true,
      title: (
        <NavLink to={routersPath.MAIN_PAGE}>
          <IconHomeDash width='22' height='22' fill='var(--color-main)' />
          Главная
        </NavLink>
      ),
      pages: [routersPath.MAIN_PAGE],
    },
    {
      show: true,
      title: (
        <DropdownUI
          menu={{
            items: [
              {
                key: routersPath.BASES,
                label: (
                  <NavLink to={routersPath.BASES}>
                    <span>Мои базы</span>
                  </NavLink>
                ),
                icon: <IconFolderAlt width='22' height='22' fill='var(--color-main)' />,
              },
              {
                key: routersPath.BASES_IMPORT,
                label: (
                  <NavLink to={routersPath.BASES_IMPORT}>
                    <span>Импорт базы</span>
                  </NavLink>
                ),
                icon: <IconFolderImport width='22' height='22' fill='var(--color-main)' />,
              },
              {
                key: routersPath.BASES_EXPORT,
                label: (
                  <NavLink to={routersPath.BASES_EXPORT}>
                    <span>Экспорт баз</span>
                  </NavLink>
                ),
                icon: <IconInboxDownload width='22' height='22' fill='var(--color-main)' />,
              },
              {
                key: routersPath.BASES_CLIENT_FIND,
                label: (
                  <NavLink to={routersPath.BASES_CLIENT_FIND}>
                    <span>Поиск клиента</span>
                  </NavLink>
                ),
                icon: <IconFolderSearch width='22' height='22' fill='var(--color-main)' />,
              },
              {
                key: routersPath.BASES_DOUBLE_FIND,
                label: (
                  <NavLink to={routersPath.BASES_DOUBLE_FIND}>
                    <span>Поиск дублей</span>
                  </NavLink>
                ),
                icon: <IconFolderShield width='22' height='22' fill='var(--color-main)' />,
              },
            ],
          }}
        >
          <Space>
            <span>
              <IconDatabase width='22' height='22' fill='var(--color-main)' />
              Базы данных
            </span>

            <span className='arrow'>
              <IconAngleDown width='16' height='16' fill='#fff' />
            </span>
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.BASES,
        routersPath.BASE,
        routersPath.BASES_IMPORT,
        routersPath.BASES_CLIENT_FIND,
        routersPath.BASES_DOUBLE_FIND,
        routersPath.CLIENT_PAGE,
      ],
    },
    {
      show: true,
      title: (
        <DropdownUI
          menu={{
            items: [
              {
                disabled: false,
                key: routersPath.MANAGER_SETTINGS,
                label: (
                  <NavLink to={routersPath.MANAGER_SETTINGS}>
                    <span>Предустановки</span>
                  </NavLink>
                ),
                icon: <IconBoltSquareAlt width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: false,
                key: routersPath.BASE_SETTINGS,
                label: (
                  <NavLink to={routersPath.BASE_SETTINGS}>
                    <span>Настройки баз</span>
                  </NavLink>
                ),
                icon: <IconReceiptText width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: false,
                key: routersPath.STATUSES,
                label: (
                  <NavLink to={routersPath.STATUSES}>
                    <span>Статусы</span>
                  </NavLink>
                ),
                icon: <IconClipboardCheck width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: false,
                key: routersPath.MANAGERS_PAGE,
                label: (
                  <NavLink to={routersPath.MANAGERS_PAGE}>
                    <span>Менеджеры</span>
                  </NavLink>
                ),
                icon: <IconUsersGroup width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: false,
                key: routersPath.PERMISSIONS,
                label: (
                  <NavLink to={routersPath.PERMISSIONS}>
                    <span>Группы доступа</span>
                  </NavLink>
                ),
                icon: <IconUsersGroup width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: !permissions.whiteList,
                key: routersPath.WHITE_LIST,
                label: (
                  <NavLink to={routersPath.WHITE_LIST}>
                    <span>Белый список</span>
                  </NavLink>
                ),
                icon: <IconKey width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: false,
                key: routersPath.ONLINE_USERS,
                label: (
                  <NavLink to={routersPath.ONLINE_USERS}>
                    <span>Юзеры онлайн</span>
                  </NavLink>
                ),
                icon: <IconUserEye width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: !permissions.scripts,
                key: routersPath.SCRIPTS_PAGE,
                label: (
                  <NavLink to={routersPath.SCRIPTS_PAGE}>
                    <span>Скрипты</span>
                  </NavLink>
                ),
                icon: <IconReceiptText width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: !permissions.tests,
                key: routersPath.TESTS,
                label: (
                  <NavLink to={routersPath.TESTS}>
                    <span>Тесты</span>
                  </NavLink>
                ),
                icon: <IconClipboardEdit width='22' height='22' fill='var(--color-main)' />,
              },
              {
                disabled: !permissions.tests,
                key: routersPath.LINKS,
                label: (
                  <NavLink to={routersPath.LINKS}>
                    <span>Ссылки</span>
                  </NavLink>
                ),
                icon: <LinkOutlined width='22' height='22' />,
              },
            ].filter((el) => !el.disabled),
          }}
        >
          <Space>
            <span>
              <IconSettings width='22' height='22' fill='var(--color-main)' />
              Настройки
            </span>

            <span className='arrow'>
              <IconAngleDown width='16' height='16' fill='#fff' />
            </span>
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.MANAGER_SETTINGS,
        routersPath.MANAGERS_PAGE,
        routersPath.WHITE_LIST,
        routersPath.ONLINE_USERS,
        routersPath.SCRIPTS_PAGE,
        routersPath.SCRIPT_EDIT,
        routersPath.SCRIPT_CREATE,
        routersPath.TESTS,
        routersPath.ADD_TEST,
        routersPath.UPDATE_TEST,
        routersPath.RESULT_TEST,
        routersPath.SUMMARY_RESULT_TEST,
      ],
    },
    {
      show: permissions.statistic,
      title: (
        <NavLink to={routersPath.STAT_PAGE}>
          <IconChartBar width='22' height='22' fill='var(--color-main)' />
          Статистика
        </NavLink>
      ),
      pages: [routersPath.STAT_PAGE, routersPath.STAT_MANAGER_PAGE],
    },
    {
      show: permissions.salary,
      title: (
        <DropdownUI
          menu={{
            items: [
              {
                key: routersPath.SALARY_INPUT,
                label: <NavLink to={routersPath.SALARY_INPUT}>Приход</NavLink>,
                icon: <IconDollarSendSquare width='22' height='22' fill='var(--color-main)' />,
              },
              {
                key: routersPath.SALARY_OUTPUT,
                label: (
                  <NavLink to={routersPath.SALARY_OUTPUT}>
                    <span>Расход</span>
                  </NavLink>
                ),
                icon: <IconDollarReceiveSquare width='22' height='22' fill='var(--color-main)' />,
              },
              {
                key: routersPath.SALARY_CRYPTO_CHANGE,
                label: (
                  <NavLink to={routersPath.SALARY_CRYPTO_CHANGE}>
                    <span>Вывод крипты / Расчет ЗП</span>
                  </NavLink>
                ),
                icon: <IconSackDollar width='22' height='22' fill='var(--color-main)' />,
              },
            ],
          }}
        >
          <Space>
            <span>
              <IconDollar width='22' height='22' fill='var(--color-main)' />
              Зарплата
            </span>

            <span className='arrow'>
              <IconAngleDown width='16' height='16' fill='#fff' />
            </span>
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.SALARY_INPUT,
        routersPath.SALARY_OUTPUT,
        routersPath.SALARY_CALC,
        routersPath.SALARY_CRYPTO_CHANGE,
        routersPath.SALARY_SUMMARY_REPORT,
      ],
    },
    // {
    //   show: permissions.generator && !isEndSubscription,
    //   title: (
    //     <NavLink to={routersPath.DOC_GEN}>
    //       <IconPrint width='22' height='22' fill='var(--color-main)' />
    //       Генератор документов
    //     </NavLink>
    //   ),
    //   pages: [routersPath.DOC_GEN, routersPath.DOC_PREVIEW],
    // },
    {
      show: permissions.generator && !isEndSubscription,
      title: (
        <DropdownUI
          menu={{
            items: [
              {
                key: routersPath.DOC_GEN,
                label: <NavLink to={routersPath.DOC_GEN}>Генератор документов</NavLink>,
                icon: <IconPrint width='22' height='22' fill='var(--color-main)' />,
              },
              {
                key: routersPath.NEW_DOC_GEN,
                label: <NavLink to={routersPath.NEW_DOC_GEN}>Новый генератор документов</NavLink>,
                icon: <IconPrint width='22' height='22' fill='var(--color-main)' />,
              },
              {
                key: routersPath.NEW_DOC_GEN_SETTINGS,
                label: (
                  <NavLink to={routersPath.NEW_DOC_GEN_SETTINGS}>Настройки генератора</NavLink>
                ),
                icon: <IconSettings width='22' height='22' fill='var(--color-main)' />,
              },
            ],
          }}
        >
          <Space>
            <span>
              <IconPrint width='22' height='22' fill='var(--color-main)' />
              Генератор документов
            </span>
            <span className='arrow'>
              <IconAngleDown width='16' height='16' fill='#fff' />
            </span>
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.DOC_GEN,
        routersPath.DOC_PREVIEW,
        routersPath.NEW_DOC_GEN,
        routersPath.NEW_DOC_PREVIEW,
      ],
    },
    // {
    //   title: 'Безопасность',
    //   index: 1,
    //   pages: [routersPath.STAT_PAGE],
    // },
  ]
}
