import React, { useMemo } from 'react'
import { useSubscriptionSelector } from '../../../../../store/selectors/auth'
import styles from './SubscriptionBtn.module.scss'
import { subscriptionMapper } from '../../../../../utils/mappers'
import { useModalContext } from '../../../../../context/modalContext'
import SubscriptionModal from '../../../../Modals/SubscriptionModal/SubscriptionModal'
import { IconBriefcase } from '../../../../../utils/icons'

const SubscriptionBtn: React.FC = () => {
  const { data: subscriptions } = useSubscriptionSelector()
  const { setModalComponent } = useModalContext()

  const label = useMemo(() => {
    if (!subscriptions) return 'нет'
    return subscriptionMapper(subscriptions?.active?.plan?.type)
  }, [subscriptions])

  const handler = () => {
    setModalComponent({ component: <SubscriptionModal />, maskClosable: true, closable: false })
  }

  return (
    <div className={styles.container} onClick={handler}>
      <IconBriefcase width='14' height='14' fill='var(--color-main)' />
      Тариф: {label}
    </div>
  )
}

export default SubscriptionBtn
