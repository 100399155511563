import React, { HTMLAttributes } from 'react'
import cn from 'classnames'
import cls from './DividerVertical.module.scss'

interface DividerVerticalProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

export const DividerVertical: React.FC<DividerVerticalProps> = ({ className, ...props }) => {
  return <div className={cn([cls.DividerVertical, className])} {...props}></div>
}
