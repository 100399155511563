import { ChatsType } from '../../utils/chat'
import { useAppSelector } from '../hooks'
import dayjs from 'dayjs'

export const useCrmInfoSelector = () => useAppSelector((state) => state.crmInfo)
export const useCrmPermission = () => useAppSelector((state) => state.crmInfo.permission)
export const useEndSubscription = () => {
  const subscription = useAppSelector((state) => state.subscriptions.data)
  return dayjs().isAfter(dayjs(subscription?.active?.endDate))
}

export const useChatToken = (): { token: string; type: ChatsType } | undefined =>
  useAppSelector((state) => JSON.parse(state?.crmInfo?.jivoToken ?? '{}'))

export const useBalance = () => useAppSelector((state) => state.crmInfo.balance)
export const useLinks = () => useAppSelector((state) => state.links.linksList)
