import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconCommentInfo = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M4.95,17.212A7.5,7.5,0,0,1,3,12c0-5,4.03-8,9-8s9,3,9,8-4.03,8-9,8a10.985,10.985,0,0,1-3.59-.58c-1.34,1.43-3.53,1.62-4.95,1.57a.478.478,0,0,1-.29-.84A4.778,4.778,0,0,0,4.95,17.212Z'
        />
        <path
          fill={fill}
          d='M12.02,10a1,1,0,0,1-.01-2h.01a1,1,0,0,1,0,2Zm.73,5V12a.75.75,0,0,0-1.5,0v3a.75.75,0,0,0,1.5,0Z'
        />
      </g>
    </svg>
  )
}

export default IconCommentInfo
