import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconWallet = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M18,7H5A2.006,2.006,0,0,1,3,5V18a2.652,2.652,0,0,0,3,3H18a2.652,2.652,0,0,0,3-3V10A2.652,2.652,0,0,0,18,7Z'
        />
        <path
          fill={fill}
          d='M16.5,11.5a2.5,2.5,0,1,0,0,5H21v-5ZM17,15a1,1,0,1,1,1-1A1,1,0,0,1,17,15Z'
        />
        <path
          fill={fill}
          d='M5,3H15a2.652,2.652,0,0,1,3,3V7H5A2.015,2.015,0,0,1,3,5,2.006,2.006,0,0,1,5,3Z'
        />
      </g>
    </svg>
  )
}

export default IconWallet
