import { useNotificationsSelector } from '../../../store/selectors/notifications'
import React, { useCallback, useEffect, useRef } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { getNotifications, readAllNotifications } from '../../../store/notifications/thunks'
import styles from './MsgList.module.scss'
import { fullDateFormat } from '../../../utils/time'
import { Empty } from 'antd'
import { useModalContext } from '../../../context/modalContext'
import NotificationModal from '../../Modals/NotificationModal/NotificationModal'
import { IconCalendar, IconCloudTimes } from '../../../utils/icons'

interface Props {
  showHandler: () => void
}

const MsgList: React.FC<Props> = ({ showHandler }) => {
  const { msg, page, newMsg } = useNotificationsSelector()
  const loader = useRef(null)
  const dispatch = useAppDispatch()
  const { setModalComponent } = useModalContext()

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0]
      if (target.isIntersecting && msg.length > 4) {
        dispatch(getNotifications(page + 1)).then()
      }
    },
    [page],
  )

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) observer.observe(loader.current)
  }, [handleObserver])

  const openHandler = (id: string) => {
    showHandler()
    setModalComponent({
      component: <NotificationModal id={id} />,
    })
  }

  const readAllHandler = async () => {
    await dispatch(readAllNotifications())
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.drop}>
        {msg.length > 0 ? (
          <>
            {msg.map((item) => {
              return (
                <div key={item.id} className={styles.item} onClick={() => openHandler(item.id)}>
                  <div className={styles.itemTitle}>{item.title}</div>
                  <div className={styles.footer}>
                    <div className={styles.itemDate}>
                      <IconCalendar width='14' height='14' fill='var(--color-grey)' />
                      {fullDateFormat(item.createdAt)}
                    </div>
                    {!item.isRead && <div className={styles.isNew}>новое</div>}
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <div className={styles.empty}>
            <IconCloudTimes width='54' height='54' fill='var(--color-main)' />
            <p>Нет сообщений</p>
          </div>
        )}
        <div className={styles.loader} ref={loader} />
      </div>
      <div className={styles.footer}>
        <button className={styles.btn} disabled={!newMsg} onClick={readAllHandler}>
          Отметить все как прочитанные
        </button>
      </div>
    </div>
  )
}

export default MsgList
