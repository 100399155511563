import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconDatabase = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M20,10.05V12c0,2.21-3.58,4-8,4s-8-1.79-8-4V10.05c1.66,1.5,4.56,2.45,8,2.45S18.35,11.55,20,10.05ZM12,17.5c-3.44,0-6.34-.95-8-2.45V17c0,2.21,3.58,4,8,4s8-1.79,8-4V15.05C18.35,16.55,15.44,17.5,12,17.5Z'
        />
        <ellipse fill={fill} cx='12' cy='7' rx='8' ry='4' />
      </g>
    </svg>
  )
}

export default IconDatabase
