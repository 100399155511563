import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconCloudTimes = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M13.792,7.621A6.5,6.5,0,1,0,9.5,19H16A5,5,0,0,0,16,9a5.226,5.226,0,0,0-.97.09A6.51,6.51,0,0,0,13.792,7.621Z'
        />
        <path
          fill={fill}
          d='M13.061,13.5l.969-.97a.75.75,0,0,0-1.06-1.06l-.97.969-.97-.969a.75.75,0,0,0-1.06,1.06l.969.97-.969.97a.75.75,0,1,0,1.06,1.06l.97-.969.97.969a.75.75,0,0,0,1.06-1.06Z'
        />
      </g>
    </svg>
  )
}

export default IconCloudTimes
