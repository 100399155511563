import { setBtc, setUsd, setUpdateTime } from './reducer'
import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { CurrencyPath } from '../../constants/currency'

interface MonoCurses {
  currencyCodeA: number
  currencyCodeB: number
  date: number
  rateBuy: string
  rateSell: string
}

export const getPrice = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const {
      currency: { updateTime },
    } = getState()
    const timeNow = Date.now()
    if (timeNow - updateTime > 60000) {
      const {
        data: {
          bitcoin: { usd },
        },
      }: AxiosResponse<{ bitcoin: { usd: number } }> = await axiosInstance.get(CurrencyPath.BTC)
      const { data }: AxiosResponse<MonoCurses[]> = await axiosInstance.get(CurrencyPath.USD)
      dispatch(setUsd(Number(Number(data[0].rateBuy.toString()).toFixed(2))))
      dispatch(setBtc(usd))
      dispatch(setUpdateTime(timeNow))
    }
  } catch (e) {
    console.log(e)
  }
}
