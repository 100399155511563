import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFolderAlt = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path fill={fill} d='M3,17a2.652,2.652,0,0,0,3,3H18a2.652,2.652,0,0,0,3-3V15H3Z' />
        <path
          fill={fill}
          opacity={0.3}
          d='M18,7H13L10,4H6A2.652,2.652,0,0,0,3,7v8H21V10A2.652,2.652,0,0,0,18,7Z'
        />
      </g>
    </svg>
  )
}

export default IconFolderAlt
