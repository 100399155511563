import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { RefreshTokenResponseDto } from '../../dto/login/user.response.dto'
import { IUser } from '../../interfaces/user.interface'

export interface ITheme {
  theme: 'dark' | 'light'
}

export const initialThemeState: ITheme = {
  theme: 'dark',
}

export const themeSlice = createSlice({
  name: 'themes',
  initialState: initialThemeState,
  reducers: {
    setTheme: (state: Draft<ITheme>, { payload }) => {
      state.theme = payload.theme
    },
  },
})

export const { setTheme } = themeSlice.actions

export default themeSlice.reducer
